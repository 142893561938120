// HandAnimation.js
import React, { useEffect } from 'react';
import './HandAnimation.scss';

const HandAnimation = () => {
  useEffect(() => {
    const hand = document.querySelector('.hand-animation');

    const showHand = () => {
      hand.classList.add('show');
      setTimeout(() => {
        hand.classList.remove('show');
      }, 3000); // Длительность анимации (например, 3 секунды)
    };

    const interval = setInterval(showHand, 50000); // Интервал появления анимации (например, каждые 5 секунд)

    return () => clearInterval(interval);
  }, []);

  return <div className="hand-animation">SWIPE!</div>;
};

export default HandAnimation;
