import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, AppBar, Toolbar, IconButton, useMediaQuery, ThemeProvider, createTheme } from "@mui/material";
import { SubjectOutlined, AddCircleOutlineOutlined } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import Header from "../Header/Header";
import SignInUp from "../Auth/SignInUp";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArticleIcon from '@mui/icons-material/Article';
import ChecklistIcon from '@mui/icons-material/Checklist';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from '../../firebase';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

var drawerWidth = 240;
const useStyles = makeStyles((theme) => {
        return {
            page: {
                background: '#212121',
                width: '100vw',
                flexGrow: 1,
                
                transition: 'margin-left 0.2s ease',
                marginLeft: -drawerWidth,
            },
            pageShift: {
                width: '100vw',
                background: '#212121',
                
                marginLeft: 0,
                transition: 'margin-left 0.2s ease',
                boxShadow: 'none',
            },
            appBar: {
                background: "#212121",
                transition: 'margin-left 0.2s ease',
                marginLeft: drawerWidth,
                boxShadow: 'none',
            },
            
            appBarShift: {
                background: "#212121",
                marginLeft: 0,
                transition: 'margin-left 0.2s ease',
                width: '97%',
            },
            drawer: {
                width: drawerWidth,
            },
            drawerPaper: {
                width: drawerWidth,
            },
            drawerPaperBlack:{
                width: drawerWidth,
            },
            root: {
                display: 'flex',
            },
            toolbar: {
                marginBottom: "150px"
            },
            title: {
                flexGrow: 1, //прикольная штука, делает большой отспут к header
            },
            '@media screen and (max-width: 1150px)': {
                pageShift: {
                    marginLeft: -drawerWidth,
                },
                pageShift_zeropadding: {
                    marginLeft: -drawerWidth,
                },
                page: {
                    padding: theme.spacing(0)
    
                },
            }
        };
    }
);


    

const Layout = ({ children, themeMode, toggleTheme }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [isTournamentsPage, setIsTournamentsPage] = useState(false);
    const [isTournamentsGridPage, setIsTournamentsGridPage] = useState(false);
    const [isCsgoPage, setIsCsgoPage] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerRef = useRef(null); // Добавляем ref для Drawer
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        setIsTournamentsPage(location.pathname === '/tournaments');
    }, [location.pathname]);
    useEffect(() => {
        setIsTournamentsGridPage(location.pathname === '/tournamentsgrid');
    }, [location.pathname]);
    useEffect(() => {
        setIsCsgoPage(location.pathname === '/csgo');
    }, [location.pathname]);

    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);


    //пути, для которых вы хотите установить padding в 0
    const zeroPaddingPaths = ['/csgo', '/tournamentsgrid'];
    const shouldSetZeroPadding = zeroPaddingPaths.includes(location.pathname);
      
      const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#333'
          },
          secondary: {
            main: '#fff'
          },
        }
      });

    const menu = [
        {
            text: "Головна",
            icon: <SubjectOutlined color="secondary"/>,
            path: "/"
        },
        {
            text: "Петиції",
            icon: <ArticleIcon color="secondary"/>,
            path: "/petitions"
        },
        {
            text: "Новини",
            icon: <AnnouncementIcon color="secondary"/>,
            path: "/news"
        },
        {
            text: "Турніри",
            icon: <EmojiEventsIcon color="secondary"/>,
            path: "/tournaments"
        },

        (user && (user.email === "andrey.knapp@gmail.com" || user.email === "knappteam.org@gmail.com")) && {
            text: "Адмін",
            icon: <AdminPanelSettingsIcon color="secondary"/>,
            path: "/admin"
        }
    ].filter(Boolean);

    const mobileMenu = [
        {
            text: "Головна",
            icon: <SubjectOutlined color="secondary" />,
            path: "/"
        },
        {
            text: "Петиції",
            icon: <ArticleIcon color="secondary" />,
            path: "/petitions"
        },
        {
            text: "Новини",
            icon: <AnnouncementIcon color="secondary" />,
            path: "/news"
        },
        {
            text: "Про нас",
            icon: <InfoIcon color="secondary" />,
            path: "/about"
        },
        {
            text: "Наші цілі",
            icon: <ChecklistIcon color="secondary" />,
            path: "/goals"
        },
        {
            text: "Турніри",
            icon: <EmojiEventsIcon color="secondary" />,
            path: "/tournaments"
        },
        (user && (user.email === "andrey.knapp@gmail.com" || user.email === "knappteam.org@gmail.com")) && {
            text: "Адмін",
            icon: <AdminPanelSettingsIcon color="secondary"/>,
            path: "/admin"
        }
    ].filter(Boolean);

    const isMobile = useMediaQuery('(max-width:1150px)'); // Пример медиа-запроса

    const menuItems = isMobile ? mobileMenu : menu;

    const handleBackgroundClick = () => {
        if (isDrawerOpen) {
          setIsDrawerOpen(false);
        }
      };

    return (
        <div className={classes.root}>

            <AppBar elevation={0}> {/*убирает тень*/}
                <Toolbar className={isDrawerOpen ? classes.appBar : classes.appBarShift}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={toggleDrawer}
                    >
                        <MenuIcon color="secondary"/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>KnappTeam</Typography>
                    <Header />
                    <SignInUp className={classes.signInUp}/>
                </Toolbar>
            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor={isMobile ? "top" : "left"} // Изменение анкора в зависимости от мобильного устройства
                open={isDrawerOpen}
                classes={{
                    paper: isMobile ? classes.drawerPaperMobile : classes.drawerPaper,
                }}
            >
                <Typography variant="h4">KnappT</Typography >
                <List>
                    {menuItems.map((item) => (
                        <ListItem button key={item.text} onClick={() => {
                            navigate(item.path); // Переход по ссылке
                            toggleDrawer(); // Закрытие Drawer на мобильных устройствах
                        }}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            <div
            className={isDrawerOpen ? classes.pageShift : classes.page}
            onClick={handleBackgroundClick}
            >
                <div className={classes.toolbar} /> {/*добавляет отступ сверху appbar*/}
                {children}
            </div>

        </div>
    );
};

export default Layout;