import {Switch} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Cs2.scss";
import Question from "../../assets/images/Question.png"
import Flag from "../../assets/images/ukraine_flag.png"

export default function Cs2(){
    const [isLogo1, setIsLogo1] = useState(false);

    const handleSwitchChange = () => {
        setIsLogo1(prevState => !prevState);
    };

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    

    return(
        <main style={{width: "100%", position: "relative", height: "auto", display: "flex", flexDirection :"column", alignItems :"center", gap: "40px"}}>


            <div style={{
                position: "relative",
                width: "100%",
                height: "400px", 
                overflow: "hidden", 
                marginBottom: "50px",
                marginTop: "-70px"
                
            }}>
                <img src="https://www.strafe.com/esports-betting/strafe-news/wp-content/uploads/sites/29/CS2-Valve-lanca-novo-update-veja-detalhes.png" style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    zIndex: "0",
                    opacity: "0.7", 
                    
                }} alt="background" />
                <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(31, 12, 25, 0.9) 100%)", /* цвет градиента */
                    zIndex: "1"
                }}></div>
                <div className="header"><span>CyberCHE</span><span>2024</span></div>
            </div>
{/*_________________________________________________________________________________________________*/}
                
                <Link to="/table">
                    <div className="show_table">
                        <span>Подивитись турнірну сітку команд</span>
                    </div>
                </Link>
            
{/*_________________________________________________________________________________________________*/}

            <div className="teamLogos_wrapper">
                <span className="teamLogos_wrapper_header">Команди</span>

                <div className="show_players_div">
                    <span>Показати гравців</span>
                    <Switch
                    checked={isLogo1}
                    onChange={handleSwitchChange}
                    color="info"
                    size="xl"
                    />
                    
                </div>
                
                <div className="teamLogos">

                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">GORILKA team</span>
                        <div className="players_name">
                            <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">Despair team</span>
                        <div className="players_name">
                        <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">AND1 team</span>
                        <div className="players_name">
                        <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team" >karo4e revo team</span>
                        <div className="players_name">
                            <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">Sinagoga team</span>
                        <div className="players_name">
                        <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">BOLT team</span>
                        <div className="players_name">
                        <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">Chocobo team</span>
                        <div className="players_name">
                        <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    <div className={isLogo1 ? "logo1" : "logo"}>
                        <img src={Question}/>
                        <span className="name_of_the_team">Death team</span>
                        <div className="players_name">
                            <div><img src={Flag} /><span>Name1</span></div>
                            <div><img src={Flag} /><span>Name2</span></div>
                            <div><img src={Flag} /><span>Name3</span></div>
                            <div><img src={Flag} /><span>Name4</span></div>
                            <div><img src={Flag} /><span>Name5</span></div>
                        </div>
                    </div>
                    
                   
                </div>
            </div>


            
        </main>
    )
}