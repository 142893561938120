import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import TournamentsGrid from "../../components/TournamentsGrid/TournamentsGrid.tsx";
import PlayOff from "../../assets/images/playoff_background.png"
import "./TournamentsStat.scss";

const TournamentsStat = () => {

    const [matchtime, setMatchtime] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/table.json');
                const data = await response.json();
                setMatchtime(data.team.reverse());
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return(
        <div>
            <div style={{
                position: "relative",
                width: "100%",
                height: "300px", 
                overflow: "hidden", 
                marginBottom: "50px",
                marginTop: "-70px"
                
            }}>
                <img src={PlayOff} style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    zIndex: "0",
                    opacity: "0.7", 
                    
                }} alt="background" />
                <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(31, 12, 25, 0.9) 100%)", /* цвет градиента */
                    zIndex: "1"
                }}></div>
                <div className="header"><span>Турнірна сітка</span><span>Розклад</span></div>
            </div>
            <div className="table_grid_wrapper">
                <Paper className="table">
                    <div className="titles">
                        <span className="date">
                            Дата
                        </span>
                        <span className="time">
                            Час
                        </span>
                        <span className="teams">
                            Команди
                        </span>
                        <span className="status">
                            Статус
                        </span>
                    </div>

                    {matchtime.slice(0, 3).map((match, index) => (

                        <div className="titles">
                            <span className="date">
                                {match.date}
                            </span>
                            <span className="time">
                                {match.time}
                            </span>
                            <span className="teams">
                                {match.team1} - {match.team2}
                            </span>
                            <span className="status">
                            {match.status === "in_progress" && (
                                <p>В процесі</p>
                            )}
                            {match.status === "pending" && (
                                <p>Очікування</p>
                            )}
                            {match.status === "completed" && (
                                <p>Закінчено</p>
                            )}
                            </span>
                        </div>
                    ))}
                </Paper>
                <TournamentsGrid />
            </div>
            
        </div>
    )
}

export default TournamentsStat;