import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Tournaments from "./pages/Tournaments/Tournaments";
import SignUp from "./pages/Auth/SignUp";
import SignIn from "./pages/Auth/SignIn";
import Account from "./pages/Account/Account";
import AdminPage from "./pages/Admin/Admin";
import TournamentsStat from "./pages/Tournaments/TournamentsStat";
import TournamentRegistration from "./pages/TournamentRegistration/TournamentRegistration";
import FileUpload from "./components/FileUpload/FileUpload";
import { createTheme, ThemeProvider } from "@mui/material";
import Layout from "./components/Layout/Layout";
import { useEffect } from "react";
import Cs2 from "./pages/Cs2/CS2";
import Petitions from "./pages/Petitions/Petitions";
import About from "./pages/About/About";
import News from "./pages/News/News";
import Soccer from "./pages/Soccer/Soccer";
import Basketball from "./pages/Basketball/Basketball";
import Volleyball from "./pages/Volleyball/Volleyball";

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#FAFF00'
    },
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/account" element={<Account />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/table" element={<TournamentsStat />} />
            <Route path="/file" element={<FileUpload />} />
            <Route path="/cs2" element={<Cs2 />} />
            <Route path="/petitions" element={<Petitions />} />
            <Route path="/about" element={<About />} />
            <Route path="/news" element={<News />} />
            <Route path="/soccer" element={<Soccer />} />
            <Route path="/basketball" element={<Basketball />} />
            <Route path="/volleyball" element={<Volleyball />} />
            <Route path="/tournament_registration" element={<TournamentRegistration />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
