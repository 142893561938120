import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Paper } from "@mui/material";
import "./Auth.scss"
import { Link, useNavigate } from "react-router-dom";

const SignIn =  () => {
    const [reset_text, setReset_text] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const [textEmailFirst, setTextEmailFirst] = useState(false);
    const navigate = useNavigate();

    useEffect(() =>{
        const listen = onAuthStateChanged(auth, (user) => {
            if(user) {
                setAuthUser(user);
            }else {
                setAuthUser(null);
            }
        })

    }, [])

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signIn = (e) =>{
        e.preventDefault();

        if (!email.trim()) {
            emailFirst();
            return;
        }
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            console.log(userCredential)
            navigate('/')
        }).catch((error) => {
            console.log(error)
        })
    }
    const resetPassword = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log("reset password was sent")
        })
        .catch((error) => {
            console.log(error)
        });
        setReset_text(true);
    }

    const emailFirst = () => {
        setTextEmailFirst(true);
    }

    return (
        <div>
            <Paper className="signIn_wrapper">
                <form onSubmit={signIn} style={{display: "flex", flexDirection: "column", width: "100%", gap: "20px", alignItems: "center"}}>
                    <div className="first_form_div">
                        <h1>Увійти</h1>
                        <Link to="/signup" style={{color: "#FAFF00"}}>Зареєструватися</Link>
                    </div>
                    <div className="label_input_div">
                        <label className="label">Введіть ваш email</label>
                        <input type="email" placeholder="Введіть вай email" value={email} onChange={(e) => setEmail(e.target.value)} ></input>
                    </div>
                    <div className="label_input_div">
                        <label>Введіть ваш пароль</label>
                        <input type="password" placeholder="Введіть ваш пароль" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    </div>
                   
                    <div className="form_button">
                        <button type="submit" >Увійти</button>
                        <span onClick={resetPassword}>Забули пароль?</span>
                    </div>
                    {reset_text && (
                        <div style={{color: "red"}}>
                            Посилання на відновлення паролю було надіслано на вашу електрону пошту.
                        </div>
                    )}

                    {textEmailFirst &&(
                        <div style={{color: "red"}}>
                            Спочатку введіть електрону пошту
                        </div>
                    )}
                    
                    
                </form>
            </Paper>
        </div>
    )
}

export default SignIn;