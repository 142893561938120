import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./TournamentsGrid.scss"
import { Bracket, IRoundProps, Seed, SeedItem, SeedTeam, IRenderSeedProps } from 'react-brackets';
import { TextField } from "@mui/material";
import HandAnimation from "../HandAnimation/HandAnimation";

// const BracketContainer = styled.div`
//   background-color: #f0f0f0;
//   padding: 20px;
// `;


const TournamentsGrid = () =>{

  const [teams, setTeams] = useState({});

  
  useEffect(() => {
    const fetchTeams = async () => {
        try {
            const response = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/Teams.json');
            const data = await response.json();
            setTeams(data.teams);
        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        }
    };

    fetchTeams();
}, []);

const rounds: IRoundProps[] = [
  {
    title: 'Round one',
    seeds: [
      {
        id: 1,
        date: new Date().toDateString(),
        teams: [{ name: teams['team1'] }, { name: teams['team2'] }],
      },
      {
        id: 2,
        date: new Date().toDateString(),
        teams: [{ name: teams['team3'] }, { name: teams['team4'] }],
        style: {border: "2px solid black"}
      },
      {
        id: 3,
        date: new Date().toDateString(),
        teams: [{ name: teams['team5'] }, { name: teams['team6'] }],
      },
      {
        id: 4,
        date: new Date().toDateString(),
        teams: [{ name: teams['team7'] }, { name: teams['team8'] }],
      },
      {
        id: 5,
        date: new Date().toDateString(),
        teams: [{ name: teams['team9'] }, { name: teams['team10'] }],
      },
      {
        id: 6,
        date: new Date().toDateString(),
        teams: [{ name: teams['team11'] }, { name: teams['team12'] }],
        style: {border: "2px solid black"}
      },
      {
        id: 7,
        date: new Date().toDateString(),
        teams: [{ name: teams['team13'] }, { name: teams['team14'] }],
      },
      {
        id: 8,
        date: new Date().toDateString(),
        teams: [{ name: teams['team15'] }, { name: teams['team16'] }],
      },
    ],
  },
  {
    title: 'Round two',
    seeds: [
      {
        id: 1,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
      {
        id: 2,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
      {
        id: 3,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
      {
        id: 4,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
    ],
  },
  {
    title: 'Round three',
    seeds: [
      {
        id: 1,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
      {
        id: 2,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
    ],
  },
  {
    title: 'Round four',
    seeds: [
      {
        id: 1,
        date: new Date().toDateString(),
        teams: [{ name: 'Team A' }, { name: 'Team C' }],
      },
    ],
  },
  
];





return(
  <div>
     <Bracket rounds={rounds} />
     <div className="handAnimation_div">
        <HandAnimation />
     </div>
     
  </div>
  
)
}

export default TournamentsGrid;