import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import "./Account.scss"
import { Paper, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const Account = () => {

    const [authUser, setAuthUser] = useState(null);

    useEffect(() =>{
        const listen = onAuthStateChanged(auth, (user) => {
            if(user) {
                setAuthUser(user);
            }else {
                setAuthUser(null);
            }
        })
        return () =>{
            listen()
        }

    }, [])

    const ssignOut = () =>{
        signOut(auth).then(() => {
            console.log("sign out is succsesful")
        }).catch(error => console.log(error))
    }
    return(
        <div>
            <Paper className="account_wrapper">
                <p className="urAccountLabel">Ваш аккаунт</p>

                <div className="ur_account">
                    <PersonIcon />
                    {authUser ? <><span>{`Ви увійшли як ${authUser.email}`}</span></> : "Ви не увійшли в аккаунт"}
                </div>
                {authUser ? <div onClick={ssignOut} className="signOut_button">Вийти з аккаунту</div> : ''}
                
            </Paper>
            
        </div>
    )
}

export default Account;