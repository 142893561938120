import React from "react";
import Cs2 from "../../assets/images/Counter-strike_2.jpg"
import Twitch from "../../assets/images/Twitch.jpg"
import Nentsk from "../../assets/images/Nenats.png"
import "./Tournaments.scss";
import { Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Tournaments = () => {
    return(
        <div className="tournaments_mainWrapper">

            {/*____________________________*/}
            <div className="right_side_phone">
                <Typography variant="h4" color="secondary" style={{fontSize: "4vw", textAlign: "center"}}>Очікувані турніри</Typography>
                    <div className="tournaments_event_wrapper">
                        
                        
                        <Link to="/cs2">
                            <div className="tournaments_event_phone"> 
                                <div className="tournament_name_div_phone">
                                    <span className="name_of_event">
                                        CS2 Cherkasy tournament
                                    </span>
                                    <span className="date_div_phone">26.04</span>
                                </div>
                                
                                <p className="tournament_prize">
                                    4тис. грн | 8teams
                                </p>
                            </div>
                        </Link>
                        <Link to="/csgo">
                            <div className="tournaments_event_phone"> 
                                <div className="tournament_name_div_phone">
                                    <span className="name_of_event">
                                        CS2 Cherkasy tournament
                                    </span>
                                    <span className="date_div_phone">26.04</span>
                                </div>
                                
                                <p className="tournament_prize">
                                    4тис. грн | 8teams
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>

            <div className="left_side">

                <div className="sides_wrapper">
                    <div className="tournaments_left">
                        <div  className="border_empty_block"></div>
                        <div className="cs2_block_image"><img src={`./${Cs2}`}/></div>
                    </div>
                    <div className="right">
                        <p>Турніри з CS2 тебе чекають! Грай в свою улюблену гру та <i style={{color: "#FAFF00"}}>заробляй гроші!</i> Професійні коментатори, стріми на твічі, онлайн турнірна сітка команд, змагальна атмосфера!</p>
                        <Link to="/tournament_registration"><div>Реєструйся!</div></Link>
                    </div>
                </div>

                <div className="sides_wrapper">
                    
                    <div className="right">
                        <p>Гра кожної команди транслюється на Twitch та озвучується коментаторами.
                            <ul>
                                <i style={{color: "#FAFF00"}}>Це дає змогу:</i>
                                <li>Аналізувати свої помилки!</li>
                                <li>Аналізуавти гру супротивників!</li>
                            </ul>
                        </p>
                    </div>
                    <div className="tournaments_left">
                        <div className="border_empty_block"></div>
                        <div className="cs2_block_image"><img src={`./${Twitch}`} style={{transform: "rotate(90deg)"}}/></div>
                    </div>
                </div>

                <div className="sides_wrapper">
                    <div className="tournaments_left">
                        <div className="border_empty_block"></div>
                        <div className="cs2_block_image"><img src={`./${Nentsk}`}/></div>
                    </div>
                    <div className="right">
                        <p>Всі матчі відбуваютсья на дискорд сервері <i style={{color: "#FAFF00"}}>наших спонсорів.</i>
                            Для кожної команди відведені окремі голосові кімнати, в котрих вони грають
                        </p>
                    </div>
                </div>

            </div>

            {/*____________________________*/}

            <div className="right_side">
            <div className="tournaments_event_wrapper">
                        <Typography variant="h4" color="secondary">Очікувані турніри</Typography>
                        
                        <Link to="/cs2">
                            <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} className="tournaments_event"> 
                                <CardContent>
                                    <div className="tournament_name_div">
                                    <Typography gutterBottom variant="h6" component="div" color="secondary">
                                        CS2 Ukraine tournament
                                    </Typography>
                                    <span className="date_div">10.06</span>
                                    </div>
                                    
                                    <Typography variant="body2" color="secondary" >
                                        6тис. грн | 16teams
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                        <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} className="tournaments_event"> {/* Прозрачность 70% */}
                            <CardContent>
                                <div className="tournament_name_div">
                                <Typography gutterBottom variant="h6" component="div" color="secondary">
                                    Dota2 tournament
                                </Typography>
                                <span className="date_div">01.07</span>
                                </div>
                                
                                <Typography variant="body2" color="secondary" >
                                    4тис. грн | 8teams
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
            </div>

        </div>
    )
}

export default Tournaments;