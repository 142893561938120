import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { Button, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AdminGrid from '../../components/AdminGrid/AdminGrid.tsx';

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [team1, setTeam1] = useState('');
  const [team2, setTeam2] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [status, setStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        if (currentUser.email !== 'andrey.knapp@gmail.com' && currentUser.email !== 'knappteam.org@gmail.com') {
          // Если пользователь не совпадает с ожидаемым email-адресом, перенаправляем его на другую страницу
          navigate('/');
        }
      } else {
        // Если пользователь не аутентифицирован, перенаправляем его на страницу входа
        navigate('/signin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const [matchtime, setMatchtime] = useState([]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/table.json');
        const data = await response.json();
        setMatchtime(data.team.reverse());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleAddMenuToggle = () => {
    setShowAddMenu(!showAddMenu); // Переключение видимости формы при нажатии на кнопку
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formData = {
        team1: team1,
        team2: team2,
        date: date,
        time: time,
        status: status
    };

    fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/table.json')
        .then(response => response.json())
        .then(data => {
            console.log('Current data:', data);

            // Проверяем, является ли data.team массивом
            if (!Array.isArray(data.team)) {
                data.team = [];
            }

            // Добавляем новую команду в массив
            data.team.push(formData);
            console.log('Updated data:', data);

            // Отправляем запрос на обновление данных
            fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/table.json', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                if (response.ok) {
                    console.log('Data successfully added');
                }
            })
            .catch(error => {
                console.error('Error sending request:', error);
            });
        })
        .catch(error => {
            console.error('Error loading data:', error);
        });
};

  return (
    <div>
      {user ? (
        <div>
          <Typography variant='h3' color="primary">Привіт, {user.email}</Typography>
          <Paper>
            <div className="titles">
              <span className="date">
                Дата
              </span>
              <span className="time">
                Час
              </span>
              <span className="teams">
                Команди
              </span>
              <span className="status">
                Статус
              </span>
            </div>

            {matchtime.slice(0, 3).map((match, index) => (
              <div className="titles" key={index}>
                <span className="date">
                  {match.date}
                </span>
                <span className="time">
                  {match.time}
                </span>
                <span className="teams">
                  {match.team1} - {match.team2}
                </span>
                <span className="status">
                  {match.status === "in_progress" && (
                    <p>В процесі</p>
                  )}
                  {match.status === "pending" && (
                    <p>Очікування</p>
                  )}
                  {match.status === "completed" && (
                    <p>Закінчено</p>
                  )}
                </span>
              </div>
            ))}

          </Paper>
          
          <div>
            <Button variant='outlined' onClick={handleAddMenuToggle}>
              <span>Додати матч</span>
              <AddIcon />
            </Button>
          </div>

          {showAddMenu && ( // Отображение формы только если showAddMenu равно true
            <form noValidate autoComplete="off" className='show_add_menu' onSubmit={handleSubmit}>
              <TextField
                onChange={(e) => setTeam1(e.target.value)}
                variant="outlined"
                label="Перша команда"
                required
                fullWidth
            />
            <TextField
                onChange={(e) => setTeam2(e.target.value)}
                variant="outlined"
                label="Друга команда"
                required
                fullWidth
            />
            <TextField
                onChange={(e) => setDate(e.target.value)}
                variant="outlined"
                label="Дата"
                required
                fullWidth
            />
            <TextField
                onChange={(e) => setTime(e.target.value)}
                variant="outlined"
                label="Час"
                required
                fullWidth
            />
            <RadioGroup aria-label="status" name="status" value={status} onChange={handleStatusChange}>
                <FormControlLabel
                    value="pending"
                    control={<Radio icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                    label="Очікування"
                />
                <FormControlLabel
                    value="in_progress"
                    control={<Radio icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                    label="У процессі"
                />
                <FormControlLabel
                    value="completed"
                    control={<Radio icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                    label="Закінчено"
                />
            </RadioGroup>
              <Button type="submit">
                Додати
              </Button>
            </form>
          )}

        <AdminGrid />

        </div>
      ) : (
        <p>Загрузка...</p>
      )}
    </div>
  );
};

export default AdminPage;

