import React, { useState } from "react";
import axios from "axios";
import "./TournamentRegistration.scss";
import Solo from "../../assets/images/solo.png";
import Team from "../../assets/images/team.png";

const TournamentRegistration = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        teamLogo: null,
        teamName: '',
        personalData: {
            name: "",
            email: "",
            phone: "",
            telegram: ""
        },
        teamData: {
            name: "",
            members: Array(7).fill({ name: "", telegram: "" })
        }
    });

    const handlePersonalDataChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                [name]: value
            }
        }));
    };

    const handleTeamDataChange = (index, field, value) => {
        setFormData(prevData => {
            const updatedMembers = [...prevData.teamData.members];
            updatedMembers[index] = {
                ...updatedMembers[index],
                [field]: value
            };
            return {
                ...prevData,
                teamData: {
                    ...prevData.teamData,
                    members: updatedMembers
                }
            };
        });
    };

    const handleTeamName = (event) => {
        const { value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            teamName: value
        }));
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData(prevData => ({
                ...prevData,
                teamLogo: reader.result // reader.result будет содержать base64 код изображения
            }));
        };
        reader.readAsDataURL(file);
    };

    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    };

    const submitData = async () => {
        try {
            const response = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/registration.json');
            const existingData = await response.json();
            
            // Append new formData to existing data
            existingData.push(formData);
            
            // Send the updated data back to the server
            const updateResponse = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/registration.json', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(existingData)
            });
            
            if (updateResponse.ok) {
                alert("Data submitted successfully!");
            } else {
                alert("Failed to submit data.");
            }
        } catch (error) {
            console.error("Error submitting data", error);
            alert("Failed to submit data.");
        }
    };

    return (
        <div className="tournamentRegistration_main">
            {step === 1 && (
                <div className="team_or_solo_wrapper">
                    <div className="team_or_solo" onClick={nextStep}>
                        <span>Без команди/неповна</span>
                        <img src={Solo} alt="Solo" />
                    </div>
                    <div className="team_or_solo" onClick={nextStep}>
                        <span>Є команда</span>
                        <img src={Team} alt="Team" />
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className="personal_data_wrapper">
                    <p style={{color: "#FAFF00", fontSize: "3vw", fontWeight: "bold"}}>Стань чемпіоном!</p>
                    <div className="personal_data">
                        <p style={{color: "#FFFFFF", fontSize: "2vw", fontWeight: "bold"}}>Персональні дані:</p>
                        <form className="personal_data_form">
                            <div className="personal_data_form_div_phone">
                                <div>
                                    <span>Прізвище та ім'я:</span>
                                    <input
                                    placeholder="ваше прізвище, ім'я"
                                    name="name"
                                    value={formData.personalData.name}
                                    onChange={handlePersonalDataChange}
                                />
                                </div>
                                <div>
                                    <span>Email:</span>
                                    <input
                                        placeholder="ваш email"
                                        name="email"
                                        value={formData.personalData.email}
                                        onChange={handlePersonalDataChange}
                                    />
                                </div>
                                <div>
                                    <span>Номер телефону:</span>
                                    <input
                                        placeholder="ваш номер телефону"
                                        name="phone"
                                        value={formData.personalData.phone}
                                        onChange={handlePersonalDataChange}
                                    />
                                </div>
                                <div>
                                    <span>Telegram username:</span>
                                    <input
                                        placeholder="@username"
                                        name="telegram"
                                        value={formData.personalData.telegram}
                                        onChange={handlePersonalDataChange}
                                    />
                                </div>
                            </div>
                            <div className="personal_data_form_div">
                                <span>Прізвище та ім'я:</span>
                                <span>Email:</span>
                                <span>Номер телефону:</span>
                                <span>Telegram username:</span>
                            </div>
                            <div className="inputs personal_data_form_div">
                                <input
                                    placeholder="ваше прізвище, ім'я"
                                    name="name"
                                    value={formData.personalData.name}
                                    onChange={handlePersonalDataChange}
                                />
                                <input
                                    placeholder="ваш email"
                                    name="email"
                                    value={formData.personalData.email}
                                    onChange={handlePersonalDataChange}
                                />
                                <input
                                    placeholder="ваш номер телефону"
                                    name="phone"
                                    value={formData.personalData.phone}
                                    onChange={handlePersonalDataChange}
                                />
                                <input
                                    placeholder="@username"
                                    name="telegram"
                                    value={formData.personalData.telegram}
                                    onChange={handlePersonalDataChange}
                                />
                            </div>
                        </form>
                        <button type="button" className="continue" onClick={nextStep}>Продовжити</button>
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className="team_data_wrapper">
                    <p style={{color: "#FAFF00", fontSize: "3vw", fontWeight: "bold"}}>Стань чемпіоном!</p>
                    <div className="team_data">
                        <p style={{color: "#FFFFFF", fontSize: "2vw", fontWeight: "bold"}}>Командні дані:</p>
                        <form className="personal_data_form">

                            <div className="team_data_div_phone">
                                <div style={{width: "60%"}}>
                                    <div>
                                        <span className="data_span">Завантажити логотип</span>
                                        <div className="description">у разі ненаявності логотипу, вам буде виданий класичний</div>
                                    </div>
                                    <input type="file" onChange={handleFileUpload} />
                                </div>

                               
                                    
                                <div style={{width: "60%"}}>
                                    <span className="data_span">Назва команди:</span>
                                    <div className="description">забороняютсья назви, котрі порушують загально-людські норми, цінності. Забороняються назви, котрі можуть мати в собі певні історичні або воєнні події. Також слова непристойного характеру </div>
                                    <input
                                        onChange={handleTeamName}
                                        style={{ display: "inline-block", backgroundColor: "#191919", color: "#FFFFFF", borderRadius: "5px", height: "30px" }}
                                        value={formData.teamName}
                                    />
                                </div>

                                <div style={{width: "100%"}}>
                                    <div>
                                        <span className="data_span">Персональні дані учасників:</span>
                                        <div className="description">заповнюючий форму, рахується за капітана команди. Всього дозволяється 2 запасних учасників команди.</div>
                                    </div>
                                    <div className="team_members_names_and_telegram_div">
                                        <div className="team_name_surname">
                                            <span style={{ width: "200px" }}>Прізвище та ім'я</span>
                                            <span style={{ width: "250px" }}>Telegram @username</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                                                <span className="data_span">Гравець №1</span>
                                                <span className="data_span">Гравець №2</span>
                                                <span className="data_span">Гравець №3</span>
                                                <span className="data_span">Гравець №4</span>
                                                <span className="data_span">Гравець №5</span>
                                                <span className="data_span">Гравець №6</span>
                                                <span className="data_span">Гравець №7</span>
                                            </div>
                                            <div className="member_line">
                                                {formData.teamData.members.map((member, index) => (
                                                    <div key={index}>
                                                        <input
                                                            style={{ marginRight: "30px" }}
                                                            placeholder={`Гравець №${index + 1} ім'я`}
                                                            value={member.name}
                                                            onChange={(e) => handleTeamDataChange(index, 'name', e.target.value)}
                                                        />
                                                        <input
                                                            placeholder={`Гравець №${index + 1} Telegram`}
                                                            value={member.telegram}
                                                            onChange={(e) => handleTeamDataChange(index, 'telegram', e.target.value)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>

                            <div className="labels">
                                <div>
                                    <span>Завантажити логотип</span>
                                    <div className="description">у разі ненаявності логотипу, вам буде виданий класичний</div>
                                </div>
                                <div>
                                    <span>Назва команди:</span>
                                    <div className="description">забороняютсья назви, котрі порушують загально-людські норми, цінності. Забороняються назви, котрі можуть мати в собі певні історичні або воєнні події. Також слова непристойного характеру </div>
                                </div>
                                <hr style={{width: "300%"}} />
                                <div>
                                    <span>Персональні дані учасників:</span>
                                    <div className="description">заповнюючий форму, рахується за капітана команди. Всього дозволяється 2 запасних учасників команди.</div>
                                </div>
                            </div>
                            <div className="team_inputs">
                                <input type="file" onChange={handleFileUpload} />
                                {formData.teamLogo && <img src={formData.teamLogo} alt="Team Logo" style={{ width: '100px', height: '100px', marginTop: '10px' }} />}
                                <input
                                    onChange={handleTeamName}
                                    style={{ display: "inline-block", backgroundColor: "#191919", color: "#FFFFFF", borderRadius: "5px", height: "30px" }}
                                    value={formData.teamName}
                                />
                                <div className="team_members_names_and_telegram_div">
                                    <div className="team_name_surname">
                                        <span style={{ width: "200px" }}>Прізвище та ім'я</span>
                                        <span style={{ width: "250px" }}>Telegram @username</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                                            <span>Гравець №1, ви</span>
                                            <span>Гравець №2</span>
                                            <span>Гравець №3</span>
                                            <span>Гравець №4</span>
                                            <span>Гравець №5</span>
                                            <span>Гравець №6</span>
                                            <span>Гравець №7</span>
                                        </div>
                                        <div className="member_line">
                                            {formData.teamData.members.map((member, index) => (
                                                <div key={index}>
                                                    <input
                                                        style={{ marginRight: "30px" }}
                                                        placeholder={`Гравець №${index + 1} ім'я`}
                                                        value={member.name}
                                                        onChange={(e) => handleTeamDataChange(index, 'name', e.target.value)}
                                                    />
                                                    <input
                                                        placeholder={`Гравець №${index + 1} Telegram`}
                                                        value={member.telegram}
                                                        onChange={(e) => handleTeamDataChange(index, 'telegram', e.target.value)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button type="button" className="continue" onClick={nextStep}>Продовжити</button>
                    </div>
                </div>
            )}

            {step === 4 && (
                <div className="payment_wrapper">
                    <p style={{color: "#FAFF00", fontSize: "3vw", fontWeight: "bold"}}>Стань чемпіоном!</p>
                    <div className="payment">
                        <p className="payment_title">Оплата</p>
                        <div>
                            <ol type="1" className="ol_payment">
                                <li>Не ознайомлені з умовами оплати? Загляніть на сторінку регламенту - регламент.</li>
                                <li>Перейдіть в додаток вашого банку та оплатіть зазначену суму - 500грн (за команду) Номер карти: 5375414144578654. В коментарях зазначте назву вашої команди. </li>
                                <li>Після оплати киньте скріншот оплати нам у телеграм - кинути скріншот</li>
                            </ol>
                            <p className="have_mistrust">Маєте недовіру через необізнаність? Загляніть на сторінку довіри, там ви знайдете всю інформацію про попередні турніри.</p>
                        </div>
                        <button type="button" className="continue" onClick={() => { submitData(); nextStep(); }}>Я сплатив. Завершити</button>
                    </div>
                </div>
            )}

            {step === 5 && (
                <div className="lastStep_wrapper">
                    <p style={{color: "#FAFF00", fontSize: "3vw", fontWeight: "bold"}}>Стань чемпіоном!</p>
                    <div className="lastStep">
                        <p className="payment_title">Останній крок</p>
                        <div>
                            <p className="payment_title">Обов'язково перейди до группи телеграм, де ти можеш</p>
                            <ol type="1" className="ol_payment">
                                <li>Задавати питання про турнір</li>
                                <li>Спілкуватися з іншими учасниками команди</li>
                            </ol>
                        </div>
                        <button type="button" className="continue" onClick={() => window.open('https://t.me/knappteam_cyberche', '_blank')}>Перейти до групи Telegram</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TournamentRegistration;


