import React from "react";
import "./About.scss";
import logo from "../../assets/images/knapp_logo.jpg"

const About = () => {
    return(
        <div className="about_main">
            <div className="sides_about_wrapper">
                <div className="left_about">
                        <div  className="border_empty_block"></div>
                        <div className="cs2_block_image"><img src={`${logo}`}/></div>
                </div>
                <div className="right_about">
                    <p>Привіт! Ми команда організаторів турнірів по всій Україні! <i>Проводимо турніри по різним іграм: CS2, Dota 2, Fortnite.</i> Концепція наших турнірів дуже проста : всі матчі, окрім фіналу відбуваються онлайн та коментуйються професійними коментаторами, а фінал проводиться локально в кіберспортивних клубах, в залежності від місцея розташування фіналістів.</p>
                    <ol>
                        <i>Наші цілі:</i>
                        <li>Розширювати свою сферу впливу. Проводити турніри по всім містам України, та за межами країни.</li>
                        <li>Збільшення кількості турнірів в місяць.</li>
                        <li>Збільшення кількості ігрових дисциплін.</li>
                    </ol>
                </div>
            </div>
            <div>
                <button type="button"className="join_organization" onClick={() => window.open('https://t.me/andrey_aventodor', '_blank')}>Приєнуйся до команди!</button>
            </div>
        </div>
    )
}

export default About;