import React, { useState } from "react";
import "./AdminGrid.scss"
import { Typography } from "@mui/material";

const AdminGrid = () => {

    const [team1, setTeam1] = useState('');
    const [team2, setTeam2] = useState('');
    const [team3, setTeam3] = useState('');
    const [team4, setTeam4] = useState('');
    const [team5, setTeam5] = useState('');
    const [team6, setTeam6] = useState('');
    const [team7, setTeam7] = useState('');
    const [team8, setTeam8] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const teamsObject = {
            team1, team2, team3, team4, team5, team6, team7, team8
        };
    
        // Удаляем пустые команды из объекта
        for (let key in teamsObject) {
            if (teamsObject[key].trim() === '') {
                delete teamsObject[key];
            }
        }
    
        // Проверяем, есть ли хотя бы одна команда
        const hasTeam = Object.keys(teamsObject).length > 0;
    
        if (hasTeam) {
            console.log('Teams object:', teamsObject);
    
            fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/Teams.json')
                .then(response => response.json())
                .then(data => {
                    console.log('Current data:', data);
    
                    // Проверяем, является ли data.teams объектом
                    if (typeof data.teams !== 'object' || Array.isArray(data.teams)) {
                        data.teams = {};
                    }
    
                    // Обновляем данные
                    Object.assign(data.teams, teamsObject);
                    console.log('Updated data:', data);
    
                    // Отправляем запрос на обновление данных
                    fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/Teams.json', {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => {
                        if (response.ok) {
                            console.log('Data successfully added');
                        }
                    })
                    .catch(error => {
                        console.error('Error sending request:', error);
                    });
                })
                .catch(error => {
                    console.error('Error loading data:', error);
                });
        }
    };

    return(
        <div>
            <Typography variant="h3" color="primary">Оновлення турнірної сітки</Typography>
            <form className="form_adminGrid" onSubmit={handleSubmit}>

                <div className="input_block_adminGrid_wrapper">
                    <div className="input_block_adminGrid">
                        <input onChange={(e) => setTeam1(e.target.value)}></input>
                        <input onChange={(e) => setTeam2(e.target.value)}></input>
                        <input onChange={(e) => setTeam3(e.target.value)}></input>
                        <input onChange={(e) => setTeam4(e.target.value)}></input>
                        <input onChange={(e) => setTeam5(e.target.value)}></input>
                        <input onChange={(e) => setTeam6(e.target.value)}></input>
                        <input onChange={(e) => setTeam7(e.target.value)}></input>
                        <input onChange={(e) => setTeam8(e.target.value)}></input>
                    </div>
                    <div className="input_block_adminGrid">
                        <input></input>
                        <input></input>
                        <input></input>
                        <input></input>
                    </div>
                    <div className="input_block_adminGrid">
                        <input></input>
                        <input></input>
                    </div>
                    <div className="input_block_adminGrid">
                        <input></input>
                    </div>
                </div>

                
                <button type="submit">Готов</button>
                
            </form>
        </div>
    )
}

export default AdminGrid;
