import React, { useState } from 'react';

function FileUpload() {
  const [fileData, setFileData] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const bytes = new Uint8Array(arrayBuffer);
        setFileData(bytes);

        // Create a blob from the bytes and generate a URL for the image
        const blob = new Blob([bytes], { type: file.type });
        const url = URL.createObjectURL(blob);
        setImageSrc(url);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {imageSrc && (
        <div>
          <img src={imageSrc} alt="Uploaded" />
        </div>
      )}
    </div>
  );
}

export default FileUpload;