import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Card, CardContent, Grid, Paper } from "@mui/material";

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      padding: "20px",
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transition: "transform 0.6s ease",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
      }
    },
    title: {
      marginBottom: "100px",
    },
  });



const News = () => {
  const classes = useStyles();
  const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/News.json');
                const data = await response.json();
                setNews(data.news.reverse());
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title} color="primary">
          Останні новини
        </Typography>
        <Grid container spacing={3}>
          {news.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {item.details}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
};

export default News;