import React, { useState, useEffect } from 'react';
import './Petitions.scss';
import { Grid, Paper, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => {
  return ({
    button: {
      width: "20%",
      background: "#FAFF00 !important",
    },
  });
});

export default function Petitions() {
  const classes = useStyles();
  const [petitions, setPetitions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mytestjson1.s3.eu-north-1.amazonaws.com/petitions.json');
        const data = await response.json();
        setPetitions(data.petitions.reverse());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      <Grid container spacing={3} class="petition-wrapper">
      <div className="text_buton_wrapper">
        <Typography variant='h2' className="petition_description_of_the_page" color="primary">Всі петиції</Typography>
        <Button
          onClick={(event) => {
            event.preventDefault(); // Предотвращаем стандартное поведение браузера
            window.open("https://t.me/makePetitionBot");
          }}
          type="button"
          className={classes.button}
          variant='contained'
          endIcon={<AddIcon fontSize='large'/>}
        >
          <Typography variant='h6'>Створити</Typography>
        </Button>
      </div>
      
        {petitions.map((petition, index) => (
          <div  key={index}>
            <Paper className="petition" >
              <div className="petition_title">
                <span><Typography variant='h5' color="primary">Прізвище та ім'я: {petition.name}</Typography></span><span>№: {petition.id}</span>
              </div>
              <div className="petition_text">
                {petition.petition}
              </div>
            </Paper>
          </div>
        ))}      
      </Grid>

    </div>
  );
}