import React, { useState } from "react";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "./Auth.scss";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";

const SignUp =  () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signUp = (e) =>{
        e.preventDefault();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            console.log(userCredential)
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div>
             <Paper className="signIn_wrapper">
             <form onSubmit={signUp} style={{display: "flex", flexDirection: "column", width: "100%", gap: "20px", alignItems: "center"}}>
                    <div className="first_form_div">
                        <h1>Зареєструватися</h1>
                        <Link to="/signup" style={{color: "#FAFF00"}}>Увійти</Link>
                    </div>
                    <div className="label_input_div">
                        <label className="label">Введіть ваш email</label>
                        <input type="email" placeholder="Введіть вай email" value={email} onChange={(e) => setEmail(e.target.value)} ></input>
                    </div>
                    <div className="label_input_div">
                        <label>Введіть ваш пароль</label>
                        <input type="password" placeholder="Введіть ваш пароль" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    </div>
                    <div className="form_button">
                        <button type="submit">Зареєсруватися</button>
                    </div>
                    
                </form>
            </Paper>
        </div>
    )
}

export default SignUp;