import { Link, useLocation } from "react-router-dom";
import React from "react";
import "./Header.scss";
import { Typography } from "@mui/material";

export default function Header() {
    const location = useLocation();
    const getLinkStyle = (path) => {
        return location.pathname === path ? { color: "#FAFF00", fontSize: "20px", fontWeight: "lighter" } : { fontSize: "20px", fontWeight: "lighter" };
    };

    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <Link to="/">
                            <Typography className="page_link" style={getLinkStyle("/")}>Головна</Typography>
                        </Link>
                    </li>
                    <li>
                        <Link to="/about">
                            <Typography className="page_link" style={getLinkStyle("/about")}>Про нас</Typography>
                        </Link>
                    </li>
                    <li>
                        <Link to="/tournaments">
                            <Typography className="page_link" style={getLinkStyle("/tournaments")}>Турніри</Typography>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
