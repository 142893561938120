import { Container, Typography, Grid, createTheme, Paper, Card, CardMedia, CardContent, useMediaQuery, Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Home.scss'
import { makeStyles } from '@mui/styles';
import games from '../../assets/images/Games.png';
import footballImage from '../../assets/images/football_1.png';
import basketballImage from '../../assets/images/basketball.png';
import volleyballImage from '../../assets/images/voleyball_1.png';
import petitionImage from '../../assets/images/petition_image.png'
import csgo from '../../assets/images/csgo_1.png';
import knappLogo from '../../assets/images/knapp_logo.png';
import { useTheme } from "@emotion/react";


const useStyles = makeStyles((theme) => ({
    title: {
      marginBottom: '70px', 
    },
    description: {
      color: '#333', 
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "300px", 
      margin: "10px", 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transition: "transform 0.6s ease",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
      }
    },
    '@media screen and (max-width: 1150px)':{
      card:{
        width: "150px"
      }
      
    }
  }));
  

  const sportsData = [
    {
      name: 'Футбол',
      icon: footballImage,
      link: '/soccer'
    },
    {
      name: 'Баскетбол',
      icon: `./${basketballImage}`,
      link: '/basketball'
    },
    {
      name: 'Волейбол',
      icon: volleyballImage,
      link: '/volleyball'
    },
    {
        name: 'CS 2',
        icon: csgo,
        link: '/cs2'
      },
  ];
  
  
  

export default function Home() {

    const theme = useTheme();
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    
    return (
        <div className="container">

            <Grid container justify="center" className="grid">
              <div>
                <Typography variant="h3" color="primary" className="title" style={{ fontSize: matches ? '5rem' : '3rem', fontWeight: "bold",wordWrap: "break-word" }}>Реєструйся на турніри та вигравай!</Typography>
                  <Button
                  className="button"
                  type="button" 
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const tournamentsElement = document.getElementById('tournaments');
                    if (tournamentsElement) {
                        tournamentsElement.scrollIntoView({ behavior: "smooth" });
                    }
                }}
                  >
                    <Typography variant="h6" color="#000000" style={{fontWeight: "bold"}}>Розпочати</Typography>
                  </Button>
              </div>
                <Grid item>
                    <img src={`./${games}`} alt="Games" className="sport-image"/>
                </Grid>
            </Grid>

            <div style={{display: "flex"}}>
              
              <Paper className="block">
                <img src={`./${knappLogo}`} className="block_image_logo pc"/>
                <div className="pc">
                  <p className="paragraph1">Трішки про нашу організацію</p>
                  <p className="paragraph2">Ми команда, яка займається організацією турнірів з різних дисциплін. Наша мета - збудувати найбільшу в Україні організацію, котра проводить турніри!</p>
                </div>
                <div className="phone">
                  <img src={`./${knappLogo}`} className="block_image_logo"/>
                  <p className="paragraph1">Трішки про нашу організацію</p>
                </div>
                 <p className="paragraph2 phone" >Ми команда, яка займається організацією турнірів з різних дисциплін. Наша мета - збудувати найбільшу в Україні організацію, котра проводить турніри!</p>

              </Paper>
            </div>
            

            <Typography variant="h2" className="tournaments" id="tournaments" color="primary">Турніри</Typography>
            <Grid container justify="center" className="card-container">
                {sportsData.map((sport, index) => (
                    <Grid item key={index}>
                      <Link to={sport.link}>
                        <Card className={classes.card}>
                              <CardMedia
                                  className="card-media"
                                  title={sport.name}
                              >
                                  <div className="icon-container">
                                      <img src={sport.icon} className="image"/>
                                  </div>
                              </CardMedia>
                              <CardContent>
                                  <Typography gutterBottom variant="h5" component="h2" className="card-title">
                                      {sport.name}
                                  </Typography>
                              </CardContent>
                          </Card>               
                      </Link>                 

                    </Grid>
                ))}
            </Grid>
            <Grid container justify="center" flexDirection="column" alignItems="center">
              <Typography variant="h2" color="primary">Інше</Typography>
              <Link to="/petitions">
                        <Card className={classes.card}>
                              <CardMedia
                                  className="card-media"
                                  title="Петиції"
                              >
                                  <div className="icon-container">
                                      <img src={`./${petitionImage}`} className="image_petition"/>
                                  </div>
                              </CardMedia>
                              <CardContent>
                                  <Typography gutterBottom variant="h5" component="h2" className="card-title">
                                      Петиції
                                  </Typography>
                              </CardContent>
                          </Card>               
                      </Link>      
            </Grid>


        </div>
    )
}