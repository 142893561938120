import React, { useEffect, useState } from "react";
import "./SignInUp.scss";
import { Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import PersonIcon from '@mui/icons-material/Person';

const SignInUp = () => {

    const [authUser, setAuthUser] = useState(null);

    useEffect(() =>{
        const listen = onAuthStateChanged(auth, (user) => {
            if(user) {
                setAuthUser(user);
            }else {
                setAuthUser(null);
            }
        })

    }, [])

    return(
        <div>
            {authUser ? (
            <Link to="/account">
                <div style={{marginRight: "40px"}}>
                    <PersonIcon color="primary"/>
                </div>
            </Link>
            
            ) : (
            <div className="auth_wrapper">
                <Link to="/signin">
                <div className="auth signIn">
                    SignIn
                </div>
                </Link>
                <Link to="/signup">
                <div className="auth signUp">
                    SignUp
                </div>
                </Link>
            </div>
            )}
        </div>

        

    )
}

export default SignInUp;
